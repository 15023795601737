import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { defaultReactQueryParams } from '../../utils/request';
import { useIdentifier } from '../global/useIdentifier';
import { BasicQuery, ListQuery } from '../../types/request';
import { ListResult } from '../../types/response';
import { useQueryApi } from '../global/useApi';
import { useQueryPortalServiceApi } from '../global/usePortalServiceApi';
import { ActivityApplicationItem } from '../../pages/Activities/types';

export type UseActivitiesProps = ListQuery & {
  start_date: string;
  end_date: string;
  user_ids?: string;
  execs?: string;
  url?: string;
  go2_member_ids?: string;
  member_names?: string;
};
export function useActivities<T = ActivityApplicationItem>(
  params: UseActivitiesProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseActivitiesProps>({
    params,
    baseIdentifier: 'useActivities',
    identifierKeys: [
      'start_date',
      'end_date',
      'user_ids',
      'execs',
      'url',
      'go2_member_ids',
      'member_names',
    ],
    queryParamKeys: [
      'start_date',
      'end_date',
      'user_ids',
      'execs',
      'url',
      'go2_member_ids',
      'member_names',
    ],
  });

  return useQueryPortalServiceApi<UseActivitiesProps, ListResult<T>>(
    identifiers,
    '/go2-agent/activity',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select(data) {
        data.results = data.results.map((item: any) => ({
          ...item,
          id: item.id || `${item.go2_member_id}_${item.start_time}`,
          start: item.start || item.start_time,
          end: item.end || item.end_time,
          exec: item.exec || item.app,
        }));

        return data;
      },
    },
  );
}
