import { Typography } from '@mui/material';
import React, { CSSProperties } from 'react';
import { ReactRenderElement } from '../../types/types';

type Props = {
  icon?: ReactRenderElement;
  iconStyle?: CSSProperties;
  title: ReactRenderElement;
  style?: CSSProperties;
};

const PageTitle = ({ title, icon, iconStyle, style }: Props) => {
  return (
    <Typography
      component='h2'
      fontSize={28}
      display='flex'
      fontWeight={500}
      alignItems='center'
      flexWrap='nowrap'
      style={style}
    >
      {!!icon && (
        <span
          style={{
            fontSize: 'inherit',
            ...iconStyle,
          }}
        >
          {icon}&nbsp;
        </span>
      )}
      {title}
    </Typography>
  );
};

export default PageTitle;
