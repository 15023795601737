import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';

import DataTable from '../../components/DataTable/DataTable';

import { generateUserAudioRecordings, UserAudioRecording } from './types';
import moment from 'moment';
import { sortBy } from '../../utils/array';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';

type UserAudioRecordingsProps = {};

const audioRecordingsFieldLabelMap: {
  [K in keyof Partial<UserAudioRecording>]: string;
} = {
  url: 'Source',
};

const agentFieldStyleMap: {
  [K in keyof Partial<UserAudioRecording>]: CSSProperties;
} = {
  timestamp: {
    width: 260,
    minWidth: 260,
  },
  name: {
    width: 380,
    minWidth: 380,
  },
  url: {
    flex: 1,
    width: 0,
  },
};

const defaultAgentFields: (keyof UserAudioRecording)[] = [
  'timestamp',
  'name',
  'url',
];

const defaultActiveField: keyof UserAudioRecording = 'timestamp';

const UserAudioRecordings = (props: UserAudioRecordingsProps) => {
  const [filters, setFilters] = useState<{
    active: keyof UserAudioRecording;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [dataTableKey, setDataTableKey] = useState(0);

  const data = useMemo(() => generateUserAudioRecordings(100), []);
  const filteredData = useMemo(() => {
    return sortBy<UserAudioRecording>(
      data,
      filters.active,
      true,
      filters.order,
    );
  }, [data, filters]);
  const [currentPlayerId, setCurrentPlayerId] = useState<null | number>(null);

  const [selectedFields, setSelectedFields] = useState<
    (keyof UserAudioRecording)[]
  >([]);

  const handleColumnItem = useCallback(
    (field: keyof UserAudioRecording, item: UserAudioRecording) => {
      if (field === 'timestamp') {
        return (
          <Stack direction='row' gap={1}>
            <RecordVoiceOverOutlinedIcon
              style={{
                fontSize: 20,
                opacity: 0.5,
                marginTop: 1,
              }}
            />
            {`${moment
              .utc(item[field])
              .local()
              .format('MMM D, YYYY hh:mma Z')}`}
          </Stack>
        );
      }

      if (field === 'url') {
        return (
          <AudioPlayer
            // src={item[field]}
            src={'https://samplelib.com/lib/preview/mp3/sample-15s.mp3'}
            onPlay={() => setCurrentPlayerId(item.id)}
            onPause={() => setCurrentPlayerId(null)}
          />
        );
      }

      return item[field];
    },
    [currentPlayerId, setCurrentPlayerId],
  );
  const handleHeaderColumnItem = useCallback(
    (field: keyof UserAudioRecording) =>
      audioRecordingsFieldLabelMap[field] ??
      toTitleCase(stripToFormatterWord(field)),
    [],
  );
  const handleOnHeaderColumnClick = useCallback(
    (field: keyof UserAudioRecording, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );
  const handleOnFieldsChange = useCallback(
    (newFields: (keyof UserAudioRecording)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );

  return (
    <>
      <Typography component='div'>
        <DataTable
          key={dataTableKey}
          orderBy={filters.order}
          activeField={filters.active}
          fields={selectedFields.length ? selectedFields : defaultAgentFields}
          data={filteredData}
          fieldColumnStyleMap={agentFieldStyleMap}
          handleColumnItem={handleColumnItem}
          handleHeaderColumnItem={handleHeaderColumnItem}
          handleOnHeaderColumnClick={handleOnHeaderColumnClick}
        />
      </Typography>
    </>
  );
};

export default UserAudioRecordings;
