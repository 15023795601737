import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { Typography } from '@mui/material';

import DataTable from '../../components/DataTable/DataTable';

import {
  ActivityApplicationItem,
  generateActivityApplicationItems,
} from '../Activities/types';
import moment from 'moment';
import { sortBy } from '../../utils/array';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import ActivityChart from '../Activities/ActivityChart';

type UserSessionLogsProps = {};

const activityAppFieldLabelMap: {
  [K in keyof Partial<ActivityApplicationItem>]: string;
} = {
  duration: 'Duration',
  start: 'Time Started',
  end: 'Time Finished',
};

const agentFieldStyleMap: {
  [K in keyof Partial<ActivityApplicationItem>]: CSSProperties;
} = {
  id: {
    width: 100,
    minWidth: 100,
  },
  start: {
    width: 300,
    minWidth: 300,
  },
  end: {
    width: 300,
    minWidth: 300,
  },
  duration: {
    width: 200,
    minWidth: 200,
  },
  computer: {
    width: 200,
    minWidth: 200,
  },
};

const defaultAgentFields: (keyof ActivityApplicationItem)[] = [
  'start',
  'end',
  'duration',
  'computer',
];

const defaultActiveField: keyof ActivityApplicationItem = 'end';

const UserSessionLogs = (props: UserSessionLogsProps) => {
  const [filters, setFilters] = useState<{
    active: keyof ActivityApplicationItem;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [dataTableKey, setDataTableKey] = useState(0);

  const data = useMemo(() => generateActivityApplicationItems(100), []);
  const filteredData = useMemo(() => {
    return sortBy<ActivityApplicationItem>(
      data,
      filters.active,
      true,
      filters.order,
    );
  }, [data, filters]);
  // const totalOnline = useMemo(
  //   () => filteredData.filter((u) => u.status === 'online').length,
  //   [filteredData],
  // );

  const [selectedFields, setSelectedFields] = useState<
    (keyof ActivityApplicationItem)[]
  >([]);

  const handleColumnItem = useCallback(
    (field: keyof ActivityApplicationItem, item: ActivityApplicationItem) => {
      if (field === 'start' || field === 'end') {
        return `${moment
          .utc(item[field])
          .local()
          .format('MMM D, YYYY hh:mma Z')}`;
      }

      if ((field === 'url' || field === 'domain') && item[field]) {
        let url = item[field];
        try {
          url = new URL(
            (item[field].startsWith('http') ? '' : 'https://') + item[field],
          ).href;
        } catch (e) {}
        return (
          <a href={url} target='_blank' className='m3-button-link'>
            {item[field]}
          </a>
        );
      }

      return item[field];
    },
    [],
  );
  const handleHeaderColumnItem = useCallback(
    (field: keyof ActivityApplicationItem) =>
      activityAppFieldLabelMap[field] ??
      toTitleCase(stripToFormatterWord(field)),
    [],
  );
  const handleOnHeaderColumnClick = useCallback(
    (field: keyof ActivityApplicationItem, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );
  const handleOnFieldsChange = useCallback(
    (newFields: (keyof ActivityApplicationItem)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );

  return (
    <>
      <ActivityChart
        chartSx={{
          height: 500,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      />
      <Typography component='div'>
        <DataTable
          key={dataTableKey}
          orderBy={filters.order}
          activeField={filters.active}
          fields={selectedFields.length ? selectedFields : defaultAgentFields}
          data={filteredData}
          fieldColumnStyleMap={agentFieldStyleMap}
          handleColumnItem={handleColumnItem}
          handleHeaderColumnItem={handleHeaderColumnItem}
          handleOnHeaderColumnClick={handleOnHeaderColumnClick}
        />
      </Typography>
    </>
  );
};

export default UserSessionLogs;
