import React, { useCallback, useState } from 'react';
import { Box } from '@mui/material';

import UserTimelineModal from './UserTimelineModal';
import { M3Button } from '../../../components/M3/M3Button';
import { useModal } from '../../../components/M3/M3Modal';

type Props = {};

const UserTimeline = (props: Props) => {
  const modal = useModal();

  return (
    <>
      {/* <Box display='flex' justifyContent='center'>
        <M3Button color='primary' variant='contained' onClick={modal.open}>
          View Activity Playback
        </M3Button>
      </Box> */}
      <UserTimelineModal open={modal.isOpen} onClose={modal.close} />
    </>
  );
};

export default UserTimeline;
