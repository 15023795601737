import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import AppNavigationBar from './components/AppNavigationBar';
import Error from './pages/Error';
import AppDrawer from './components/AppDrawer';
import FileVersioningNotice from './components/FileVersioningNotice';

import { useCurrentProfile } from './hooks/profile';
import { useAppProvider } from './providers/app/app';

type Props = {
  children?: any;
};
type DrawerPage = any;

type PathSegments = DrawerPage[];
/**
 * Put all your components here that needs to be called immediately globally
 * to the app, after initialization
 */
function Home({ children }: Props) {
  const { isDrawerOpen, drawerWidth, withNavRail } = useAppProvider();
  const { error } = useCurrentProfile();
  const noAppDrawer = !withNavRail;

  const renderDrawer = () => {
    // if (noAppDrawer) return null;

    return <AppDrawer />;
  };

  /*
  if (!!error) {
    return (
      <Error
        title='User not found'
        message={<>This user no longer exist. Contact your administrator.</>}
      />
    );
  }
  */

  return (
    <>
      <FileVersioningNotice />
      <AppNavigationBar noAppDrawer={noAppDrawer} />
      <Box
        display='flex'
        sx={{
          flex: 1,
          /*
          ...(withNavRail
            ? {}
            : {
                transition: `transform ${
                  !isDrawerOpen ? '325ms' : '225ms'
                } cubic-bezier(0, 0, 0.2, 1) 0ms`,
                transform: `translateX(${
                  !isDrawerOpen ? '0' : `${drawerWidth}px`
                })`,
              }),
          */
        }}
      >
        {renderDrawer()}
        <Outlet />
      </Box>
    </>
  );
}

export default Home;
