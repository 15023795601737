import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  useLocation,
  useParams,
  Location,
  useNavigate,
} from 'react-router-dom';
import { Box } from '@mui/material';

import DocumentTitle from '../../components/DocumentTitle';
import WindowScrollTop from '../../components/WindowScrollTop';
import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import MainContainer from '../../components/MainContainer';
import UserMetadataView from './UserMetadata';
import UserTabs, {
  TabButtonParamItem,
  TabButtonParamItemNames,
} from './UserTabs';
import UserActivityLogs from './UserActivityLogs';
import UserSessionLogs from './UserSessionLogs';
import UserTimeWorked from './UserTimeWorked';
import UserSnapshots from './UserSnapshots';
import UserOnlineMeetings from './UserOnlineMeetings';
import UserKeystrokes from './UserKeystrokes';
import UserNetworkMonitoring from './UserNetworkMonitoring';
import UserAudioRecordings from './UserAudioRecordings';
import UserFilters from './UserFilters';

import { AgentUser } from '../Users/types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import { useUserProvider } from '../../providers/user/user';
import { UserMetadata } from '../../types/profile';
import { getUserDisplayName } from '../../utils/user';
import moment, { Moment } from 'moment';
import { useInfinite } from '../../hooks/global/useInfinite';
import { ActivityApplicationItem } from '../Activities/types';
import {
  useActivities,
  UseActivitiesProps,
} from '../../hooks/go2-agent/activities';
import { useKeystrokes } from '../../hooks/go2-agent/keystroke';
import { getWeekRangeStartsInSunday } from '../../utils/date';

type UserDetailProps = {};

type UserDetailParams = {
  userId: string;
  tabName: TabButtonParamItemNames;
};

type UserDetailLocation = Location<null | {
  item: AgentUser;
  user: UserMetadata;
}>;

type FilterParams = {
  start_date: Moment;
  end_date: Moment;
};

const UserDetail = (props: UserDetailProps) => {
  const navigate = useNavigate();
  const { userId, tabName } = useParams<UserDetailParams>();
  const { state, pathname } = useLocation() as UserDetailLocation;
  const { users, getUser, setUniqueIdsToFetch } = useUserProvider();

  let item: AgentUser | null = state?.item ?? null;
  let user = getUser('user_id', userId) ?? state?.user;

  // Filters --------------------------------------------------
  const [filterParams, setFilterParams] = useState<FilterParams>(() => ({
    // start_date: moment().startOf('day'),
    // end_date: moment().endOf('day'),
    start_date: getWeekRangeStartsInSunday(moment()).start,
    end_date: getWeekRangeStartsInSunday(moment()).end,
  }));
  const updateFilterParams = useCallback(
    (params: Partial<FilterParams>) => {
      setFilterParams((state) => ({
        ...state,
        ...params,
      }));
    },
    [setFilterParams],
  );

  // Tabs ---------------------------------------------------------------------
  const [tabIndexName, setTabIndexName] = useState<TabButtonParamItemNames>(
    tabName ?? 'activity',
  );
  const onTabIndexHandleChange = useCallback(
    (tabParams: TabButtonParamItem, index: number) => {
      setTabIndexName(tabParams.name);
      navigate(`/users/${userId}/${tabParams.name}`, {
        state: {
          item,
          user,
        },
      });
    },
    [userId, user, item, setTabIndexName, navigate],
  );

  // Activities ----------------------------------------------------------------
  const activityLimit = 100;
  const infiniteActivities = useInfinite<
    ActivityApplicationItem,
    UseActivitiesProps
  >({
    isFlatResult: false,
    skipFetchOnInit: true,
    useQuery: useActivities,
    queryParams: {
      start_date: filterParams.start_date.format(),
      end_date: filterParams.end_date.format(),
      user_ids: userId,
      limit: activityLimit,
    },
  });

  // Keystrokes ----------------------------------------------------------------
  const keystrokeLimit = 100;
  const infiniteKeystrokes = useInfinite<any, any>({
    isFlatResult: false,
    skipFetchOnInit: true,
    useQuery: useKeystrokes,
    queryParams: {
      start_date: filterParams.start_date.format(),
      end_date: filterParams.end_date.format(),
      user_ids: userId,
      limit: keystrokeLimit,
    },
  });

  /**
   * When filter changes, it resets the activities
   */
  useEffect(() => {
    if (!user) return;

    if (tabName === 'activity') {
      infiniteActivities.reset({
        emptyList: true,
      });
    } else if (tabName === 'keystrokes') {
      infiniteKeystrokes.reset({
        emptyList: true,
      });
    }
    // eslint-disable-next-line
  }, [filterParams, tabName, user]);

  /**
   * Set's the active tab index when it changes
   */
  useEffect(() => {
    tabName && tabIndexName !== tabName && setTabIndexName(tabName);
  }, [pathname, tabName, setTabIndexName]);

  /**
   * Fetches the user's metadata
   */
  useEffect(() => {
    if (user) return;

    setUniqueIdsToFetch({
      user_ids: [userId],
    });
  }, [userId, user, users, setUniqueIdsToFetch]);

  return (
    <>
      <DocumentTitle
        title={toTitleCase(stripToFormatterWord(tabIndexName))}
        trailingTitle='Users'
      />
      <WindowScrollTop deps={[userId]} />
      <MainContainer sx={{ maxWidth: undefined }}>
        <AppBreadcrumbs
          items={[
            {
              id: 'users',
              label: 'Users',
              path: '/users',
            },
            {
              id: (user ? user.id : userId)?.toString() || 'unknown',
              label: user ? getUserDisplayName(user).fullName : userId,
              path: user ? `/users/${userId}` : '',
            },
          ]}
        />
        <Box display='flex' alignItems='stretch' gap={4}>
          <UserMetadataView item={item} user={user} />
          <Box flex={1} width={0}>
            <UserTabs
              tabIndexName={tabIndexName}
              handleOnTabChange={onTabIndexHandleChange}
            >
              <UserFilters
                filterParams={filterParams}
                updateFilterParams={updateFilterParams}
              />
              {tabIndexName === 'activity' && (
                <UserActivityLogs
                  items={infiniteActivities.data}
                  isLoading={infiniteActivities.isLoading}
                />
              )}
              {tabIndexName === 'session-log' && <UserSessionLogs />}
              {tabIndexName === 'time-worked' && <UserTimeWorked />}
              {tabIndexName === 'snapshots' && <UserSnapshots />}
              {tabIndexName === 'online-meetings' && <UserOnlineMeetings />}
              {tabIndexName === 'keystrokes' && (
                <UserKeystrokes
                  items={infiniteKeystrokes.data}
                  isLoading={infiniteKeystrokes.isLoading}
                />
              )}
              {tabIndexName === 'network-monitoring' && (
                <UserNetworkMonitoring />
              )}
              {tabIndexName === 'audio' && <UserAudioRecordings />}
            </UserTabs>
          </Box>
        </Box>
      </MainContainer>
    </>
  );
};

export default UserDetail;
