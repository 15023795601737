import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Slider, Stack, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import AbsoluteCenterBox from '../AbsoluteCenterBox';
import VolumeControl from '../AudioPlayer/VolumeControl';

import { useRefAssign } from '../../hooks/global/useRefAlwaysUpdated';
import AspectRatioContainer, {
  AspectRatioContainerProps,
} from '../AspectRatioContainer';
import SpeedControl, { useSpeedControl } from './SpeedControl';

type VideoPlayerProps = {
  src: string;
  ratio?: AspectRatioContainerProps['ratio'];
  onPause?: () => void;
  onPlay?: () => void;
  style?: CSSProperties;
  autoPlay?: boolean;
};

const formatTime = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  return [hours, minutes, seconds]
    .map((unit) => unit.toString().padStart(2, '0'))
    .join(':');
};

export function useFullscreen(div?: HTMLElement | null) {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const showFullscreen = useCallback(() => {
    if (!document.fullscreenElement) {
      if (div?.requestFullscreen) {
        div.requestFullscreen();
      }
      // Safari
      else if ((div as any)?.webkitRequestFullscreen) {
        (div as any).webkitRequestFullscreen();
      }
      // IE11
      else if ((div as any)?.msRequestFullscreen) {
        (div as any).msRequestFullscreen();
      }
    }
  }, [div]);

  const hideFullscreen = useCallback(() => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      // Safari
      else if ((document as any)?.webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      }
      // IE11
      else if ((document as any)?.msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
    }
  }, [setIsFullscreen]);

  useEffect(() => {
    const handleFullscreenChange = () =>
      setIsFullscreen(!!document.fullscreenElement);

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange,
      );
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullscreenChange,
      );
      document.removeEventListener(
        'MSFullscreenChange',
        handleFullscreenChange,
      );
    };
  }, [setIsFullscreen]);

  return useMemo(
    () => ({
      isFullscreen,
      showFullscreen,
      hideFullscreen,
    }),
    [isFullscreen, showFullscreen, hideFullscreen],
  );
}

const VideoPlayer = ({
  src,
  ratio = '4:3',
  onPause,
  onPlay,
  style,
  autoPlay,
}: VideoPlayerProps) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const currentTimeRef = useRefAssign(currentTime);
  const [isPlaying, setIsPlaying] = useState(false);
  const isPlayingRef = useRefAssign(isPlaying);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hoverTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const fullscreen = useFullscreen(containerRef.current);

  const speedControl = useSpeedControl();

  const videoData = useMemo(() => {
    if (src) {
      let type = src.split('?')[0]?.split('.').pop();
      return {
        video: (
          <video
            ref={videoRef}
            controls={false}
            autoPlay={false}
            style={{
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
              position: 'absolute',
            }}
          >
            <source src={src} type={`video/${type}`}></source>
          </video>
        ),
      };
    }

    return {
      video: null,
      type: null,
    };
  }, [src, videoRef]);

  const clearHoverTimer = useCallback(() => {
    hoverTimerRef.current && clearTimeout(hoverTimerRef.current);
    hoverTimerRef.current = null;
  }, [hoverTimerRef]);

  const onMouseEnterOnVideo = useCallback(() => {
    clearHoverTimer();
    !isHovering && setIsHovering(true);
    // Check if it's currently playing when we hover the video so we can
    // automatically hide it
    if (isPlayingRef.current || isHovering) {
      hoverTimerRef.current = setTimeout(() => setIsHovering(false), 2000);
    }
  }, [isHovering, isPlayingRef, hoverTimerRef, setIsHovering, clearHoverTimer]);

  const onMouseLeaveOnVideo = useCallback(() => {
    clearHoverTimer();
    setIsHovering(false);
  }, [setIsHovering, clearHoverTimer]);

  /**
   * Plays the video element
   */
  const playVideo = useCallback(async () => {
    try {
      await videoRef.current?.play();
      setIsPlaying(true);
    } catch (e) {
      console.log(e);
    }
  }, [videoRef, setIsPlaying]);

  /**
   * Pause the video element
   */
  const pauseVideo = useCallback(() => {
    videoRef.current?.pause();
  }, [videoRef]);

  /**
   * Sets the video element current time. Good to set after seeking.
   */
  const setVideoCurrenTime = useCallback(
    (value: number) => {
      if (videoRef.current) {
        videoRef.current.currentTime = value;
      }
    },
    [videoRef],
  );

  /**
   * Sets the total duration
   */
  const setVideoDuration = useCallback(
    () => setDuration(videoRef.current?.duration ?? 0),
    [videoRef, setDuration],
  );

  /**
   * Sets the videos current time when video is playing
   */
  const setVideoTimeUpdate = useCallback(
    (evt: Event) => {
      let video = (evt.target || evt.currentTarget) as HTMLVideoElement;
      let currentTime = (video || videoRef.current).currentTime ?? 0;

      if (isPlayingRef.current) {
        setCurrentTime(currentTime);
      }
    },
    [videoRef, isPlayingRef, setCurrentTime],
  );

  /**
   * Handle when pressing the Pause button
   */
  const handleOnPause = useCallback(() => {
    pauseVideo();
    setIsPlaying(false);
    onPause?.();
  }, [onPause, pauseVideo, setIsPlaying]);

  /**
   * Loads the video initially, and add the listeners
   */
  const loadVideo = useCallback(() => {
    let video = videoRef.current;

    if (!video) return;

    // remove event listeners
    video.removeEventListener('loadeddata', setVideoDuration);
    video.removeEventListener('timeupdate', setVideoTimeUpdate);
    video.removeEventListener('ended', handleOnPause);

    // Add event listeners
    video.addEventListener('loadeddata', setVideoDuration);
    video.addEventListener('timeupdate', setVideoTimeUpdate);
    video.addEventListener('ended', handleOnPause);
  }, [
    src,
    videoRef,
    currentTimeRef,
    setVideoDuration,
    setVideoTimeUpdate,
    handleOnPause,
  ]);

  /**
   * handle when pressing the Play button
   */
  const handleOnPlay = useCallback(() => {
    loadVideo();
    playVideo();
    setIsPlaying(true);
    onPlay?.();
  }, [onPlay, loadVideo, playVideo, setIsPlaying]);

  /**
   * handle when seeker change, when user press the switch and drag it
   */
  const handleSliderChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      handleOnPause();
      setCurrentTime(newValue as number);
    },
    [videoRef, setCurrentTime, handleOnPause],
  );

  /**
   * Handle when user release the switch
   */
  const handleSliderChangeEnd = useCallback(
    (event: SyntheticEvent | Event, newValue: number | number[]) => {
      setCurrentTime(newValue as number);
      setVideoCurrenTime(newValue as number);
      handleOnPlay();
    },
    [videoRef, setCurrentTime, setVideoCurrenTime, handleOnPlay],
  );

  const handleOnVolumeChange = useCallback(
    (volume: number) => {
      const video = videoRef.current;
      if (video) {
        video.volume = volume;
      }
    },
    [videoRef],
  );

  const iconStyle: CSSProperties = {
    cursor: 'pointer',
    opacity: 0.8,
    fontSize: 30,
    color: fullscreen.isFullscreen ? '#fff' : undefined,
  };

  /**
   * On load, get the metadata
   */
  useEffect(() => {
    loadVideo();
  }, [loadVideo]);

  /**
   * When player is not playing, pause the video
   */
  useEffect(() => {
    if (!isPlaying) {
      pauseVideo();
    }
  }, [isPlaying, pauseVideo]);

  /**
   * When it's unmounted pause the video
   */
  useEffect(() => {
    return pauseVideo;
  }, [pauseVideo]);

  /**
   * Change video speed
   */
  useEffect(() => {
    let video = videoRef.current;
    if (video) {
      video.playbackRate = speedControl.speed;
    }
  }, [speedControl.speed, videoRef]);

  useEffect(() => {
    if (autoPlay) {
      playVideo();
    }
  }, [src, autoPlay, playVideo]);

  return (
    <Box
      ref={containerRef}
      style={{
        paddingBottom: 10,
      }}
    >
      <AspectRatioContainer
        ratio={ratio}
        style={{
          ...(fullscreen.isFullscreen
            ? {
                height: '100%',
                paddingBottom: 0,
              }
            : style),
        }}
      >
        <Box
          style={{
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            userSelect: 'none',
            position: 'absolute',
            flexDirection: 'column',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          }}
        >
          <Box
            style={{
              height: 0,
              flexGrow: 2,
              position: 'relative',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
            onMouseMove={onMouseEnterOnVideo}
            onMouseEnter={onMouseEnterOnVideo}
            onMouseLeave={onMouseLeaveOnVideo}
          >
            {videoData.video}
            <AbsoluteCenterBox
              style={{
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                transition: 'opacity 200ms',
                opacity: isHovering || !isPlaying ? 1 : 0,
              }}
              onClick={isPlaying ? handleOnPause : handleOnPlay}
            >
              {isPlaying ? (
                isHovering && (
                  <PauseIcon
                    style={{
                      ...iconStyle,
                      fontSize: 60,
                      color: '#fff',
                      pointerEvents: 'none',
                    }}
                  />
                )
              ) : (
                <PlayArrowIcon
                  style={{
                    ...iconStyle,
                    fontSize: 60,
                    color: '#fff',
                    pointerEvents: 'none',
                  }}
                />
              )}
              <Stack
                gap={2}
                direction='row'
                justifyContent='flex-end'
                style={{
                  left: 0,
                  right: 0,
                  bottom: 0,
                  padding: '20px 40px',
                  position: 'absolute',
                }}
                onClick={(evt) => evt.stopPropagation()}
              >
                <SpeedControl
                  withCaret={false}
                  speed={speedControl.speed}
                  onChange={speedControl.onChange}
                  style={{
                    // left: -80,
                    top: 0,
                    left: 0,
                    position: 'relative',
                  }}
                  contentStyle={{
                    fontSize: 20,
                    color: '#fff',
                  }}
                />
                {fullscreen.isFullscreen ? (
                  <FullscreenExitIcon
                    style={{
                      ...iconStyle,
                      color: '#fff',
                    }}
                    onClick={fullscreen.hideFullscreen}
                  />
                ) : (
                  <FullscreenIcon
                    style={{
                      ...iconStyle,
                      color: '#fff',
                    }}
                    onClick={fullscreen.showFullscreen}
                  />
                )}
              </Stack>
            </AbsoluteCenterBox>
          </Box>
          <Box
            gap={1}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 6,
              paddingBottom: 6,
            }}
          >
            {isPlaying ? (
              <PauseIcon onClick={handleOnPause} style={iconStyle} />
            ) : (
              <PlayArrowIcon onClick={handleOnPlay} style={iconStyle} />
            )}
            <VolumeControl
              style={{
                marginRight: -16,
              }}
              iconStyle={{
                fontSize: 24,
                color: fullscreen.isFullscreen ? '#fff' : undefined,
              }}
              onVolumeChange={handleOnVolumeChange}
            />
            <Typography
              component='div'
              style={{
                width: 80,
                marginRight: 8,
                textAlign: 'right',
                color: fullscreen.isFullscreen ? '#fff' : undefined,
              }}
            >
              <span style={{ opacity: 0.5 }}>{formatTime(currentTime)}</span>
            </Typography>
            {/* <SpeedControl
              speed={speedControl.speed}
              onChange={speedControl.onChange}
              style={{
                left: -80,
              }}
              contentStyle={{
                color: fullscreen.isFullscreen ? '#fff' : undefined,
              }}
            /> */}
            <Box
              style={{
                flex: 1,
                top: -14,
                position: 'relative',
              }}
            >
              <Slider
                disableSwap
                size='small'
                min={0}
                max={duration}
                value={currentTime}
                disabled={!duration}
                onChange={handleSliderChange}
                onChangeCommitted={handleSliderChangeEnd}
                style={{
                  top: 0,
                  left: 0,
                  right: 0,
                  position: 'absolute',
                }}
                sx={{
                  '& .MuiSlider-thumb': {
                    transition: 'none',
                  },
                  '& .MuiSlider-track': {
                    transition: 'none',
                  },
                  '& .MuiSlider-rail': {
                    transition: 'none',
                  },
                }}
              />
            </Box>
            <Typography
              component='div'
              style={{
                width: 80,
                marginRight: 8,
                textAlign: 'right',
                color: fullscreen.isFullscreen ? '#fff' : undefined,
              }}
            >
              <span style={{ opacity: 0.8 }}>
                {formatTime(duration || currentTime)}
              </span>
            </Typography>
          </Box>
        </Box>
      </AspectRatioContainer>
    </Box>
  );
};

export default VideoPlayer;
