function getBooleanValue(value?: string): boolean {
  try {
    return JSON.parse(value!) as boolean;
  } catch (e) {
    return false;
  }
}

export type FeatureConfigKeys = any;

type FeatureConfig = {
  [K in FeatureConfigKeys]: boolean;
};

const feature: FeatureConfig = {};

export default feature;
