import React, {
  CSSProperties,
  Children,
  PropsWithChildren,
  cloneElement,
  forwardRef,
  useCallback,
  useRef,
  useState,
  MutableRefObject,
  useLayoutEffect,
  useEffect,
  useMemo,
} from 'react';
import { v4 as uuid } from 'uuid';
import { Box } from '@mui/material';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { M3IconButton } from './M3Button';

import { useAppProvider } from '../../providers/app/app';
import { IterableObject } from '../../types/types';

let counter: IterableObject<boolean> = {};

export type M3ModalProps = PropsWithChildren & {
  open: boolean;
  onClose?: () => void;
  closeOnBackdropClick?: boolean;
};

export type UseModalRes = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

export function useModal(): UseModalRes {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);

  const close = useCallback(() => setIsOpen(false), []);

  return useMemo(
    () => ({
      isOpen,
      open,
      close,
    }),
    [isOpen, open, close],
  );
}

const M3Modal = ({
  open,
  onClose,
  closeOnBackdropClick,
  children,
}: M3ModalProps) => {
  const content = (
    <>
      <Box
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 11000,
          opacity: 0.5,
          position: 'fixed',
          background: '#000',
        }}
        onClick={closeOnBackdropClick ? onClose : undefined}
      ></Box>
      <Box
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 11000,
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {Children.map(children, (child: any) => {
          return !child
            ? null
            : cloneElement(child, {
                onClose,
              });
        })}
      </Box>
    </>
  );
  const id = useMemo(() => uuid(), []);

  useLayoutEffect(() => {
    counter[id] = open;
  }, [id, open]);

  useLayoutEffect(() => {
    if (Object.values(counter).filter((v) => v).length) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [counter, open]);

  if (!open) return null;

  return createPortal(<Box>{content}</Box>, document.body);
};

type M3ModalHeaderProps = PropsWithChildren & {
  style?: CSSProperties;
  className?: string;
  disableCloseButton?: boolean;
  withCloseButton?: boolean;
  onClose?: () => void;
};
export const M3ModalHeader = forwardRef<HTMLDivElement, M3ModalHeaderProps>(
  (
    {
      children,
      className,
      style,
      disableCloseButton,
      withCloseButton = true,
      onClose,
      ...props
    },
    ref,
  ) => {
    const { isDarkMode } = useAppProvider();

    return (
      <Box
        ref={ref}
        className={clsx('m3-modal-header', className)}
        style={{
          zIndex: 20,
          display: 'flex',
          padding: '16px 16px',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: isDarkMode
            ? 'var(--md-ref-palette-primary20)'
            : 'var(--md-ref-palette-primary95)',
          borderBottom: `2px solid ${
            isDarkMode
              ? 'var(--md-ref-palette-neutral-variant30)'
              : 'var(--md-ref-palette-neutral-variant90)'
          }`,
          ...style,
        }}
        {...props}
      >
        {children}
        {withCloseButton && (
          <M3IconButton
            disabled={disableCloseButton}
            className='-mr-3'
            onClick={onClose}
          >
            <CloseOutlinedIcon />
          </M3IconButton>
        )}
      </Box>
    );
  },
);

type M3ModalFooterProps = PropsWithChildren & {
  style?: CSSProperties;
  className?: string;
  onClose?: () => void;
};
export const M3ModalFooter = forwardRef<HTMLDivElement, M3ModalFooterProps>(
  ({ children, className, style, ...props }, ref) => {
    const { isDarkMode } = useAppProvider();

    return (
      <Box
        ref={ref}
        className={clsx('m3-modal-footer', className)}
        style={{
          zIndex: 20,
          display: 'flex',
          padding: '16px 16px',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: isDarkMode
            ? 'var(--md-ref-palette-primary20)'
            : 'var(--md-ref-palette-primary95)',
          ...style,
        }}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

type M3ModalBodyProps = PropsWithChildren & {
  style?: CSSProperties;
  className?: string;
  onClose?: () => void;
};
export const M3ModalBody = forwardRef<HTMLDivElement, M3ModalBodyProps>(
  ({ children, className, style, ...props }, ref) => {
    const { isDarkMode } = useAppProvider();
    const elem = (ref as MutableRefObject<HTMLDivElement>).current;

    return (
      <Box
        ref={ref}
        className={clsx('m3-modal-body', className)}
        style={{
          position: 'relative',
          overflowX: 'hidden',
          overflowY:
            !!elem && elem?.scrollHeight > elem?.clientHeight
              ? 'auto'
              : 'hidden',
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-background-light)',
          ...style,
        }}
        {...props}
      >
        <Box className='m3-modal-body-content'>{children}</Box>
      </Box>
    );
  },
);

type M3ModalViewProps = PropsWithChildren & {
  style?: CSSProperties;
  onClose?: () => void;
};
export const M3ModalView = forwardRef(
  ({ children, style, onClose }: M3ModalViewProps, ref) => {
    const headerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    const [, setBodyStyle] = useState<CSSProperties>({});

    const getRef = (child: any) => {
      let props: any = {};
      if (!child) return undefined;
      if (child.type === M3ModalHeader) {
        props.ref = headerRef;
      } else if (child.type === M3ModalFooter) {
        props.ref = footerRef;
      } else if (child.type === M3ModalBody) {
        props.ref = bodyRef;
      }
      return props;
    };

    const onResize = useCallback(() => {
      const bodyDataMaxHeight = bodyRef.current?.dataset?.['maxHeight'];

      const gapYPx = 20;
      const headerHeight = headerRef.current?.clientHeight ?? 0;
      const footerHeight = footerRef.current?.clientHeight ?? 0;
      const maxHeight =
        window.innerHeight - gapYPx * 2 - headerHeight - footerHeight;

      const style = {
        maxHeight: bodyDataMaxHeight ?? `${maxHeight}px`,
      };

      if (bodyRef.current && maxHeight > 0) {
        Object.assign(bodyRef.current.style, style);
      }

      setBodyStyle(style);
    }, [headerRef, bodyRef, footerRef, setBodyStyle]);

    useEffect(() => {
      let resizeObserver: null | ResizeObserver = null;
      let mutationObserver: null | MutationObserver = null;
      const bodyEl = bodyRef.current as unknown as Element;
      const bodyFirstElement: HTMLDivElement | null = bodyEl?.querySelector(
        '.m3-modal-body-content',
      );

      // listen when window resizes
      window.addEventListener('resize', onResize, false);

      // add an observer when content changes its size
      if (bodyFirstElement) {
        resizeObserver = new ResizeObserver(onResize);
        resizeObserver.observe(bodyFirstElement);

        mutationObserver = new MutationObserver(onResize);
        mutationObserver.observe(bodyFirstElement, {
          childList: true,
          subtree: true,
        });
      }

      onResize();

      return () => {
        window.removeEventListener('resize', onResize, false);
        resizeObserver &&
          bodyFirstElement &&
          resizeObserver.unobserve(bodyFirstElement);
        mutationObserver && bodyFirstElement && mutationObserver.disconnect();
      };
    }, [headerRef, bodyRef, footerRef, setBodyStyle, onResize]);

    return (
      <Box
        className={clsx('m3-modal-view')}
        style={{
          maxWidth: 900,
          overflow: 'hidden',
          borderRadius: 8,
          width: 'calc(100vw - 40px)',
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
          pointerEvents: 'auto',
          ...style,
        }}
      >
        {Children.map(children, (child: any) => {
          return !child
            ? child
            : cloneElement(child, {
                ...getRef(child),
                onClose,
              });
        })}
      </Box>
    );
  },
);

export default M3Modal;
