import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

export function useRefAssign<T = any>(value: T) {
  const ref = useRef<T>(value);

  if (typeof value === 'object') {
    if (value) {
      ref.current = Object.assign({}, value);
    } else {
      ref.current = value;
    }
  } else {
    ref.current = value;
  }

  return ref;
}

type UseStateRefRet<T> = {
  value: T;
  setValue: (value: T) => void;
  ref: MutableRefObject<T>;
};
export function useStateRef<T = unknown>(initValue: T): UseStateRefRet<T> {
  const [value, setValue] = useState<T>(initValue);
  const ref = useRef<T>(value);

  const set = useCallback(
    (value: T) => {
      ref.current = value;
      setValue(value);
    },
    [ref, setValue],
  );

  return useMemo(
    () => ({
      ref,
      value,
      setValue: set,
    }),
    [ref, value, set],
  );
}
