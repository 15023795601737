import { Avatar, Box, Skeleton, Stack } from '@mui/material';
import React, { CSSProperties } from 'react';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { UserMetadata, UserProfile } from '../../types/profile';
import { getUserDisplayName, getUserPhotoUrl } from '../../utils/user';

type Props = {
  user?: UserMetadata | null;
  onClick?: () => void;
};

export const UserActivitySkeleton = ({ user, onClick }: Props) => {
  if (user) {
    let clickStyle: CSSProperties = onClick
      ? {
          cursor: 'pointer',
        }
      : {};
    return (
      <Stack direction='row' gap={1} alignItems='center'>
        <InsertLinkOutlinedIcon
          style={{
            fontSize: 14,
            opacity: 0.5,
            marginLeft: -4,
            marginRight: -2,
            cursor: 'pointer',
          }}
          onClick={onClick}
        />
        <Avatar
          src={getUserPhotoUrl(user.photo_url)}
          style={{
            width: 24,
            height: 24,
            fontSize: 14,
            ...clickStyle,
          }}
          onClick={onClick}
        >
          {getUserDisplayName(user).initial}
        </Avatar>
        <Box style={clickStyle} onClick={onClick}>
          {getUserDisplayName(user).fullName}
        </Box>
      </Stack>
    );
  }

  return (
    <Stack direction='row' gap={1} alignItems='center'>
      <InsertLinkOutlinedIcon
        style={{
          fontSize: 14,
          opacity: 0.5,
          marginLeft: -4,
          marginRight: -2,
          cursor: 'pointer',
        }}
        onClick={onClick}
      />
      <Skeleton
        variant='circular'
        style={{
          width: 24,
          height: 24,
          fontSize: 14,
        }}
      />
      <Skeleton
        variant='text'
        style={{
          width: 100,
          fontSize: 16,
        }}
      />
    </Stack>
  );
};
