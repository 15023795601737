import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Divider,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

import DataTable from '../../components/DataTable/DataTable';

import { generateUserKeystrokes, UserKeystroke } from './types';
import moment from 'moment';
import { sortBy } from '../../utils/array';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import { useDataTablePagination } from '../../components/DataTable/DataTablePagination';
import { M3IconButton } from '../../components/M3/M3Button';
import M3SurfaceContainer from '../../components/M3/M3SurfaceContainer';

type UserKeystrokesProps = {
  isLoading?: boolean;
  items: UserKeystroke[];
};

const keystrokesFieldLabelMap: {
  [K in keyof Partial<UserKeystroke>]: string;
} = {
  url: 'Host',
  exec: 'Application',
};

const keystrokesFieldStyleMap: {
  [K in keyof Partial<UserKeystroke>]: CSSProperties;
} = {
  timestamp: {
    width: 260,
    minWidth: 260,
  },
  exec: {
    width: 240,
    minWidth: 240,
  },
  url: {
    width: 280,
    minWidth: 280,
  },
  keystrokes: {
    flex: 1,
    width: 0,
  },
};

const defaultAgentFields: (keyof UserKeystroke)[] = [
  'timestamp',
  'exec',
  'url',
  'keystrokes',
];

const defaultActiveField: keyof UserKeystroke = 'timestamp';

const UserKeystrokes = ({ isLoading, items }: UserKeystrokesProps) => {
  const [filters, setFilters] = useState<{
    active: keyof UserKeystroke;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [dataTableKey, setDataTableKey] = useState(0);

  const data = useMemo(() => generateUserKeystrokes(100), []);
  const filteredData = useMemo(() => {
    return sortBy<UserKeystroke>(data, filters.active, true, filters.order);
  }, [data, filters]);
  // const totalOnline = useMemo(
  //   () => filteredData.filter((u) => u.status === 'online').length,
  //   [filteredData],
  // );

  const [selectedFields, setSelectedFields] = useState<(keyof UserKeystroke)[]>(
    [],
  );

  const handleColumnItem = useCallback(
    (field: keyof UserKeystroke, item: UserKeystroke) => {
      if (field === 'timestamp') {
        return (
          <Stack direction='row' gap={1}>
            <KeyboardAltOutlinedIcon
              style={{
                fontSize: 20,
                opacity: 0.5,
                marginTop: 1,
              }}
            />
            {`${moment.utc(item[field]).local().format('MMM D, YYYY hh:mma')}`}
          </Stack>
        );
      }

      if (field === 'url' && item[field]) {
        let url = item[field];
        try {
          url = new URL(
            (item[field].startsWith('http') ? '' : 'https://') + item[field],
          ).host;
        } catch (e) {}
        return (
          <a href={item[field]} target='_blank' className='m3-button-link'>
            {url}
          </a>
        );
      }

      return item[field];
    },
    [],
  );
  const handleHeaderColumnItem = useCallback(
    (field: keyof UserKeystroke) =>
      keystrokesFieldLabelMap[field] ??
      toTitleCase(stripToFormatterWord(field)),
    [],
  );
  const handleOnHeaderColumnClick = useCallback(
    (field: keyof UserKeystroke, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );
  const handleOnFieldsChange = useCallback(
    (newFields: (keyof UserKeystroke)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );

  // const dataTablePagination = useDataTablePagination({
  //   defaultSize: 25,
  //   items: items,
  // });

  return (
    <>
      <Divider style={{ borderWidth: 1 }} />
      <Stack direction='row' alignItems='center'>
        <Typography component='div'>
          {/* <TablePagination
            component='div'
            labelRowsPerPage={null}
            count={items.length}
            page={dataTablePagination.page - 1}
            onPageChange={(evt: any, page: number) =>
              dataTablePagination.setPage(page + 1)
            }
            rowsPerPage={dataTablePagination.size}
            onRowsPerPageChange={(evt: any) =>
              dataTablePagination.setSize(evt.target.value)
            }
            sx={{
              '.MuiToolbar-root': {
                paddingLeft: 0,
              },
              '.MuiInputBase-root': {
                background: 'transparent !important',
              },
            }}
          /> */}
        </Typography>
        <Box display='flex' justifyContent='flex-end' gap={1} flex={1} pr={1}>
          {/* <Tooltip title='Refresh'>
              <Box>
                <M3IconButton
                  onClick={() => {
                    !infiniteActivities.isLoading &&
                      infiniteActivities.reset({
                        emptyList: true,
                      });
                  }}
                >
                  <RefreshOutlinedIcon
                    className={infiniteActivities.isLoading ? 'spinner' : ''}
                  />
                </M3IconButton>
              </Box>
            </Tooltip> */}
          <Tooltip title='Download'>
            <Box>
              <M3IconButton>
                <DownloadOutlinedIcon />
              </M3IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Stack>
      <Typography component='div'>
        <DataTable
          key={dataTableKey}
          orderBy={filters.order}
          // activeField={filters.active}
          fields={selectedFields.length ? selectedFields : defaultAgentFields}
          data={items}
          loadingCount={10}
          isLoading={isLoading}
          fieldColumnStyleMap={keystrokesFieldStyleMap}
          handleColumnItem={handleColumnItem}
          handleHeaderColumnItem={handleHeaderColumnItem}
          // handleOnHeaderColumnClick={handleOnHeaderColumnClick}
        />
      </Typography>
    </>
  );
};

export default UserKeystrokes;
