import React, { FC, ReactElement, ReactNode, forwardRef } from 'react';
import { Box, SxProps } from '@mui/material';

type Props = {
  flex?: boolean;
  children?: ReactNode | ReactElement | ReactElement[] | ReactNode[];
  sx?: SxProps;
};

const MainContainer = forwardRef<any, Props>(
  ({ flex = true, children, sx }, ref) => {
    return (
      <Box
        flex={1}
        ref={ref}
        sx={{
          p: 4,
          pt: 2,
          flex: 1,
          maxWidth: 1068,
          position: 'relative',
          width: flex ? 0 : null,
          ...sx,
        }}
      >
        {children}
      </Box>
    );
  },
);

export default MainContainer;
