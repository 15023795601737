import React from 'react';
import { Box } from '@mui/material';

import { navRailWidth } from '../NavRailMenuList';
import EdgeContainerCover from '../EdgeContainerCover';
import { getSurfaceOpacityByElevation } from '../M3/M3SurfaceContainer';

import { useAppProvider } from '../../providers/app/app';
import { applyOpacityOnColor } from '../../utils/color';
import { getFromLocalStorage } from '../../utils/localstorage';
import { GroupTypes } from '../../types/profile';

type Props = {};

const AppLoader = (props: Props) => {
  const {
    toolbarHeight,
    drawerWidth,
    palettes: { main: palette },
    isDarkMode,
    withNavRail,
  } = useAppProvider();
  const background = applyOpacityOnColor(
    isDarkMode
      ? palette['md.ref.palette.primary80']
      : palette['md.ref.palette.primary40'],
    getSurfaceOpacityByElevation(2),
  );
  const lastUserIsInMobile = getFromLocalStorage('last_user_is_in_mobile');

  /**
   * Check the last role of user to show some useful loader feedback of the app
   */
  let withSidebar = withNavRail;

  /**
   * Check if last logged in user is using mobile so no default opened sidebar
   */
  if (lastUserIsInMobile) {
    withSidebar = false;
  }

  const renderAppBar = () => {
    return (
      <Box
        sx={{
          top: 0,
          left: 0,
          right: 0,
          position: 'absolute',
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-background-light)',
        }}
      >
        <Box sx={{ height: toolbarHeight, background }}></Box>
      </Box>
    );
  };

  const renderSidebar = () => {
    return (
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-background-light)',
        }}
      >
        <EdgeContainerCover />
        {withNavRail && (
          <Box
            sx={{
              height: '100%',
              width: navRailWidth,
              background: applyOpacityOnColor(
                isDarkMode
                  ? palette['md.ref.palette.primary80']
                  : palette['md.ref.palette.primary40'],
                getSurfaceOpacityByElevation(5),
              ),
            }}
          />
        )}
        <Box
          sx={{
            height: '100%',
            width: drawerWidth - navRailWidth,
            background,
          }}
        ></Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: (theme) => theme.zIndex.drawer + 1000,
        background: isDarkMode
          ? 'var(--md-sys-color-background-dark)'
          : 'var(--md-sys-color-background-light)',
      }}
    >
      {renderAppBar()}
      {withSidebar && renderSidebar()}
    </Box>
  );
};

export default AppLoader;
