import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Avatar,
  Box,
  Divider,
  Pagination,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import MainContainer from '../../components/MainContainer';
import ActivityChart from './ActivityChart';
import PageTitle from '../../components/Title/PageTitle';
import DataTable from '../../components/DataTable/DataTable';
import FiltersBoard, { ActivityFilterParams } from './FiltersBoard';
import { M3Button, M3IconButton } from '../../components/M3/M3Button';

import {
  ActivityApplicationItem,
  generateActivityApplicationItems,
} from './types';
import { sortBy } from '../../utils/array';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import {
  useActivities,
  UseActivitiesProps,
} from '../../hooks/go2-agent/activities';
import { useInfinite } from '../../hooks/global/useInfinite';
import {
  getDurationSeconds,
  getWeekRangeStartsInMonday,
  getWeekRangeStartsInSunday,
  HHMMSSOptionsJSONRet,
  toHHMMSS,
} from '../../utils/date';
import { useUserData } from '../../hooks/profile';
import { useUserProvider } from '../../providers/user/user';
import {
  getUserDisplayName,
  getUserPhotoUrl,
  getUserProfileDisplayName,
} from '../../utils/user';
import { UserActivitySkeleton } from '../../components/User/UserSkeleton';
import { M3OptionItem } from '../../components/Popover/BasicPopoverWithSearch';
import DocumentTitle from '../../components/DocumentTitle';
import InfiniteScrollListener from '../../components/InfiniteScrollListener';
import WindowScrollTop from '../../components/WindowScrollTop';
import { useNavigate } from 'react-router-dom';
import { useDataTablePagination } from '../../components/DataTable/DataTablePagination';

type ActivitiesProps = {};

const activityAppFieldLabelMap: {
  [K in keyof Partial<ActivityApplicationItem>]: string;
} = {
  id: 'ID',
  url: 'URL',
  duration: 'Duration',
  user_id: 'Name',
  exec: 'Application',
  os: 'OS',
};

const agentFieldStyleMap: {
  [K in keyof Partial<ActivityApplicationItem>]: CSSProperties;
} = {
  user_id: {
    width: 260,
    minWidth: 260,
  },
  start: {
    width: 240,
    minWidth: 240,
  },
  end: {
    width: 240,
    minWidth: 240,
  },
  exec: {
    width: 300,
    minWidth: 300,
  },
  duration: {
    width: 200,
    minWidth: 200,
  },
  title: {
    width: 400,
    minWidth: 400,
  },
  url: {
    width: 600,
    minWidth: 600,
  },
};

const defaultAgentFields: (keyof ActivityApplicationItem)[] = [
  // 'id',
  // 'name',
  // 'email',
  'user_id',
  // 'division',
  'start',
  'end',
  'duration',
  'exec',
  // 'os',
  // 'timestamp',
  // 'computer',
  // 'domain',
  'url',
  'title',
];

const defaultActiveField: keyof ActivityApplicationItem = 'end';

const Activities = (props: ActivitiesProps) => {
  const { setUniqueIdsToFetch, getUser } = useUserProvider();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<{
    active: keyof ActivityApplicationItem;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [dataTableKey, setDataTableKey] = useState(0);

  const data = useMemo(() => generateActivityApplicationItems(100), []);
  const filteredData = useMemo(() => {
    return sortBy<ActivityApplicationItem>(
      data,
      filters.active,
      true,
      filters.order,
    );
  }, [data, filters]);
  // const totalOnline = useMemo(
  //   () => filteredData.filter((u) => u.status === 'online').length,
  //   [filteredData],
  // );

  const [selectedFields, setSelectedFields] = useState<
    (keyof ActivityApplicationItem)[]
  >([]);

  const handleColumnItem = useCallback(
    (field: keyof ActivityApplicationItem, item: ActivityApplicationItem) => {
      if (field === 'duration') {
        let duration = getDurationSeconds(item.end, item.start);
        const hms = toHHMMSS(duration, {
          minDigit: 1,
          hrDigit: 1,
          secDigit: 1,
          msDigit: 1,
          json: true,
        }) as HHMMSSOptionsJSONRet;
        const { hours, minutes, seconds } = hms;

        return `${hours ? ` ${hours}h ` : ''}${minutes}m ${seconds}s`;
      }

      if (field === 'start' || field === 'end') {
        return `${moment
          .utc(item[field])
          .local()
          .format('MMM D, YYYY hh:mma')}`;
      }

      if (field === 'user_id') {
        let user =
          getUser('user_id', item.user_id) ||
          getUser('employee_id', (item as any).go2_member_id);

        return (
          <UserActivitySkeleton
            user={user}
            onClick={() => {
              navigate(`/users/${user!.id}/activity`, {
                state: {
                  item,
                  user,
                },
              });
            }}
          />
        );
      }

      if (field === 'division') {
        let user =
          getUser('user_id', item.user_id) ||
          getUser('employee_id', (item as any).go2_member_id);
        return user ? user.division?.name : '...';
      }

      if (field === 'url' && item[field]) {
        let url = item[field];
        try {
          url = new URL(
            (item[field].startsWith('http') ? '' : 'https://') + item[field],
          ).href;
        } catch (e) {}
        return (
          <a href={url} target='_blank' className='m3-button-link'>
            {item[field]}
          </a>
        );
      }

      return item[field];
    },
    [],
  );
  const handleHeaderColumnItem = useCallback(
    (field: keyof ActivityApplicationItem) =>
      activityAppFieldLabelMap[field] ??
      toTitleCase(stripToFormatterWord(field)),
    [],
  );
  const handleOnHeaderColumnClick = useCallback(
    (field: keyof ActivityApplicationItem, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );
  const handleOnFieldsChange = useCallback(
    (newFields: (keyof ActivityApplicationItem)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );

  // Filters --------------------------------------------------
  const [filterParams, setFilterParams] = useState<ActivityFilterParams>(
    () => ({
      start_date: getWeekRangeStartsInSunday(moment()).start,
      end_date: getWeekRangeStartsInSunday(moment()).end,
      users: [],
    }),
  );
  const updateFilterParams = useCallback(
    (params: Partial<ActivityFilterParams>) => {
      setFilterParams((state) => ({
        ...state,
        ...params,
      }));
    },
    [setFilterParams],
  );

  // Activities ----------------------------------------------------------------
  const activityLimit = 100;
  const infiniteActivities = useInfinite<
    ActivityApplicationItem,
    UseActivitiesProps
  >({
    skipFetchOnInit: true,
    useQuery: useActivities,
    queryParams: {
      start_date: filterParams.start_date.format(),
      end_date: filterParams.end_date.format(),
      user_ids: filterParams.users.map((u) => u.id).join(),
      limit: activityLimit,
    },
  });

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: infiniteActivities.data.map((d) => d.user_id),
    });
  }, [infiniteActivities.data, setUniqueIdsToFetch]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: filterParams.users.map((d) => d.id),
    });
  }, [filterParams.users, setUniqueIdsToFetch]);

  useEffect(() => {
    infiniteActivities.reset({
      emptyList: true,
    });
    // eslint-disable-next-line
  }, [filterParams]);

  return (
    <>
      <DocumentTitle title='Activities' />
      <MainContainer sx={{ maxWidth: undefined }}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
        >
          <PageTitle title='Applications & Websites' />
          <Stack direction='row' gap={2}>
            {/* <M3Button color='secondary'>Use Saved Filters</M3Button> */}
            <M3Button
              active={isFiltersVisible}
              onClick={() => setIsFiltersVisible(!isFiltersVisible)}
            >
              <FilterAltOutlinedIcon />
              {isFiltersVisible ? 'Hide' : 'Show'} Filters
            </M3Button>
          </Stack>
        </Box>
        <br />
        <Box display={isFiltersVisible ? undefined : 'none'}>
          <FiltersBoard
            filterParams={filterParams}
            selectedFields={selectedFields}
            allFields={defaultAgentFields}
            fieldLabelMap={activityAppFieldLabelMap}
            onFieldsChange={handleOnFieldsChange}
            updateFilterParams={updateFilterParams}
          />
        </Box>
        {/* <Box
          gap={4}
          display='flex'
          flexWrap='wrap'
          style={{
            marginTop: 14,
          }}
        >
          <ActivityChart title='Top Apps/Domains' />
          <ActivityChart title='Top Categories' />
          <ActivityChart title='Top Browsers' />
        </Box>
        <br /> */}
        <Stack direction='row'>
          <Typography component='div' style={{ marginTop: -6 }}>
            {/* <TablePagination
              component='div'
              labelRowsPerPage={null}
              // count={infiniteActivities.data.length}
              // page={activityDataTablePagination.page - 1}
              count={infiniteActivities.count}
              page={infiniteActivities.page - 1}
              // onPageChange={(evt: any, page: number) =>
              //   activityDataTablePagination.setPage(page + 1)
              // }
              // rowsPerPage={activityDataTablePagination.size}
              // onRowsPerPageChange={(evt: any) =>
              //   activityDataTablePagination.setSize(evt.target.value)
              // }
              rowsPerPage={activityLimit}
              rowsPerPageOptions={[]}
              onPageChange={(evt: any, page: number) => {
                // infiniteActivities.res;
                infiniteActivities.setPage(page);
              }}
              sx={{
                '.MuiToolbar-root': {
                  paddingLeft: 0,
                },
              }}
            /> */}
          </Typography>
          <Box display='flex' justifyContent='flex-end' gap={1} flex={1}>
            <Tooltip title='Refresh'>
              <Box>
                <M3IconButton
                  onClick={() => {
                    !infiniteActivities.isLoading &&
                      infiniteActivities.reset({
                        emptyList: true,
                      });
                  }}
                >
                  <RefreshOutlinedIcon
                    className={infiniteActivities.isLoading ? 'spinner' : ''}
                  />
                </M3IconButton>
              </Box>
            </Tooltip>
            <Tooltip title='Download'>
              <Box>
                <M3IconButton>
                  <DownloadOutlinedIcon />
                </M3IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Stack>
        <Typography component='div'>
          <DataTable
            key={dataTableKey}
            orderBy={filters.order}
            loadingCount={10}
            // activeField={filters.active}
            isLoading={infiniteActivities.isLoading}
            fields={selectedFields.length ? selectedFields : defaultAgentFields}
            // data={activityDataTablePagination.items}
            data={infiniteActivities.data}
            fieldColumnStyleMap={agentFieldStyleMap}
            handleColumnItem={handleColumnItem}
            handleHeaderColumnItem={handleHeaderColumnItem}
            // handleOnHeaderColumnClick={handleOnHeaderColumnClick}
          />
        </Typography>
      </MainContainer>
      <InfiniteScrollListener
        onReachBottom={() => infiniteActivities.loadNext()}
      />
      <WindowScrollTop />
    </>
  );
};

export default Activities;
