import React, { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import FormPanel from '../../components/Panels/FormPanel';
import FilterColumnPanel from '../../components/Filters/FilterColumnPanel';
import DateRangeSelectionPopover from '../../components/Filters/DateRangeSelectionPopover';
import UserTimeline from './UserTimeline/UserTimeline';

export type FilterParams = {
  start_date: Moment;
  end_date: Moment;
};
type UserFiltersProps = {
  filterParams: FilterParams;
  updateFilterParams: (params: Partial<FilterParams>) => void;
};

type DateFormState = {
  start_date: string;
  end_date: string;
};

const UserFilters = ({
  filterParams,
  updateFilterParams,
}: UserFiltersProps) => {
  const [formState, setFormState] = useState<DateFormState>(() => ({
    start_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
  }));

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      gap={2}
      py={2}
      pt={3}
      px={3}
    >
      <FormPanel>
        <FilterColumnPanel
          name='date'
          useTextField={false}
          displayValue={
            <Stack gap={1} direction='row'>
              <Typography
                component='h3'
                fontSize={20}
                style={{
                  opacity: 0.8,
                }}
              >{`${moment(filterParams.start_date).format(
                'MMM D, YYYY',
              )} - ${moment(filterParams.end_date).format(
                'MMM D, YYYY',
              )}`}</Typography>
              <CalendarMonthOutlinedIcon
                style={{
                  marginTop: 2,
                  opacity: 0.8,
                }}
              />
            </Stack>
          }
          paperSx={{
            width: 280,
          }}
          containerPaperStyle={{
            marginTop: -12,
          }}
          sx={{
            zIndex: 2,
          }}
          boxFieldStyle={{
            border: 0,
            background: 'transparent',
          }}
        >
          <DateRangeSelectionPopover
            startDate={filterParams.start_date.format('YYYY-MM-DD')}
            endDate={filterParams.end_date.format('YYYY-MM-DD')}
            onChange={({ start, end }) => {
              updateFilterParams({
                start_date: moment(start).startOf('day'),
                end_date: moment(end).endOf('day'),
              });
            }}
          />
        </FilterColumnPanel>
      </FormPanel>
      <UserTimeline />
    </Box>
  );
};

export default UserFilters;
