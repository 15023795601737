import { Box, Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import M3NavRailButton from './M3/M3NavRailButton';
import { getSurfaceOpacityByElevation } from './M3/M3SurfaceContainer';

import { useAppProvider } from '../providers/app/app';
import { applyOpacityOnColor } from '../utils/color';

type Props = {};

export const navRailWidth = 68;

export function getIsInAppDrawer(pathname: string) {
  /**
   * List all the route the navRail(OS) reside
   */
  return {
    // home routes
    home: ['/'].some((s) => pathname.startsWith(s)),
  };
}

const NavRailMenuList = (props: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
  } = useAppProvider();

  const { home: isInHomeAppDrawer } = getIsInAppDrawer(pathname);

  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  return (
    <Paper
      elevation={0}
      sx={{
        ...paperSx,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: navRailWidth,
        minWidth: navRailWidth,
        backgroundColor: applyOpacityOnColor(
          isDarkMode
            ? palette['md.ref.palette.primary80']
            : palette['md.ref.palette.primary40'],
          getSurfaceOpacityByElevation(5),
        ),
        border: 0,
      }}
    >
      <Box
        style={{
          height: toolbarHeight,
          minHeight: toolbarHeight,
        }}
      ></Box>
      {/* {renderDivider({ isBottom: false, isVisible: scrollY > 1 })} */}
      <Box
        flex={1}
        sx={{
          height: 0,
        }}
      >
        <M3NavRailButton
          icon={<HomeOutlinedIcon />}
          label='Home'
          active={isInHomeAppDrawer}
          onClick={() => {}}
        />
      </Box>
      {/* {renderDivider({ isBottom: true })} */}
      <Box></Box>
      <div style={{ height: 10 }}></div>
    </Paper>
  );
};

export default NavRailMenuList;
