import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import ScreenshotMonitorOutlinedIcon from '@mui/icons-material/ScreenshotMonitorOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';

import M3SurfaceContainer from '../../components/M3/M3SurfaceContainer';
import { M3TabButton } from '../../components/M3/M3TabButton';
import UserFilters from './UserFilters';
import { M3Button } from '../../components/M3/M3Button';
import UserComputer from '../../components/Computers/UserComputer';

import { AgentUser } from '../Users/types';
import { useAppProvider } from '../../providers/app/app';
import {
  getUserDisplayName,
  getUserInitials,
  getUserPhotoUrl,
} from '../../utils/user';
import { ReactRenderElement } from '../../types/types';
import { UserMetadata } from '../../types/profile';

type UserMetadataProps = {
  item?: AgentUser | null;
  user?: UserMetadata;
};

const UserMetadataView = ({ item, user }: UserMetadataProps) => {
  const { isDarkMode } = useAppProvider();

  return (
    <Box
      pt={4}
      pb={4}
      position='relative'
      sx={{
        width: 300,
        minWidth: 300,
      }}
    >
      <Box
        display='flex'
        justifyContent='center'
        sx={{
          position: 'relative',
        }}
      >
        <Box position='relative'>
          <Avatar
            sx={{
              width: 160,
              height: 160,
              fontSize: 60,
              margin: '0 auto',
            }}
            src={getUserPhotoUrl(user?.photo_url)}
          >
            {user ? getUserInitials(user.first_name).initials : ''}
          </Avatar>
          <Box
            sx={{
              width: 12,
              height: 12,
              right: 14,
              bottom: 18,
              position: 'absolute',
              borderRadius: '50%',
              background:
                item?.status === 'online'
                  ? 'green'
                  : isDarkMode
                  ? '#444748'
                  : '#e1e3e3',
              boxShadow: `0 0 0 3px ${
                isDarkMode
                  ? 'var(--md-sys-color-background-dark)'
                  : 'var(--md-sys-color-on-primary-light)'
              }`,
            }}
          />
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        pt={1}
        pb={2}
      >
        <Typography fontSize={22} fontWeight={500}>
          {user ? (
            getUserDisplayName(user).fullName
          ) : (
            <Skeleton variant='text' style={{ width: 160 }} />
          )}
        </Typography>
        <Typography component='div' fontSize={14} sx={{ opacity: 0.5 }}>
          {user ? (
            user?.email
          ) : (
            <Skeleton variant='text' style={{ width: 120 }} />
          )}
        </Typography>
      </Box>
      <Divider />
      {/* <br /> */}
      {/* <UserComputer /> */}
    </Box>
  );
};

export default UserMetadataView;
