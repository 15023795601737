import { faker } from '@faker-js/faker';

// KEYSTROKES ------------------------------------------------------------------
export const generateUserKeystrokes = (count: number): UserKeystroke[] => {
  return Array.from({ length: count }, (_, index) => ({
    id: (index + 1).toString(),
    timestamp: faker.date.recent().toISOString(),
    exec: faker.internet.domainWord(),
    url: faker.internet.domainWord(),
    host: faker.internet.domainWord(),
    keystrokes: faker.lorem
      .words(faker.number.int({ min: 10, max: 40 }))
      .split(' ')
      .map((w) => `<${w}>`)
      .join(' '),
    sync: 0,
    user_id: 1,
  }));
};

export type UserKeystroke = {
  id: string;
  timestamp: string;
  exec: string;
  url: string;
  host: string;
  sync: number;
  keystrokes: string;
  user_id: number;
};

// AUDIO RECORDINGS ------------------------------------------------------------
export const generateUserAudioRecordings = (
  count: number,
): UserAudioRecording[] => {
  return Array.from({ length: count }, (_, index) => ({
    id: index + 1,
    timestamp: faker.date.recent().toISOString(),
    name: faker.string.uuid() + '.m4a',
    url: faker.internet.url() + '/audio/' + faker.string.uuid() + '.mp3',
  }));
};

export type UserAudioRecording = {
  id: number;
  timestamp: string;
  url: string; // audio record
  name: string;
};
