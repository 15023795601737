import { createContext, useContext, useState } from 'react';

import { ReactRenderElement } from '../../types/types';

type MetadataProviderProps = {
  children?: ReactRenderElement;
};
/**
 * State that we can mutate
 */

export type FocusGroup = {};

export type ContentTypeModels = any;

type MetadataInitialState = {};
/**
 * Reducers that mutate the state
 */
type MetadataReducers = {};
/**
 * Single store
 */
type MetadataStore = MetadataInitialState & MetadataReducers;

/**
 * Initial state / store
 */
const initialStore: MetadataStore = {};

/**
 * Context Instance
 */
const MetadataContext = createContext<MetadataStore>(initialStore);

export function useMetadataProvider(): MetadataStore {
  return useContext(MetadataContext);
}

export function MetadataProvider({ children }: MetadataProviderProps) {
  const [state] = useState<MetadataStore>(initialStore);

  return (
    <MetadataContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
}
