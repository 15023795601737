import React, { PropsWithChildren } from 'react';
import { Box, SxProps, Typography } from '@mui/material';

import { ReactRenderElement } from '../../types/types';

type ActivityChartProps = PropsWithChildren & {
  title?: ReactRenderElement;
  flex?: number;
  sx?: SxProps;
  chartSx?: SxProps;
};

const ActivityChart = ({
  title,
  flex = 1,
  sx,
  chartSx,
}: ActivityChartProps) => {
  return (
    <Box flex={flex} sx={sx}>
      {title && (
        <Typography
          component='div'
          fontSize={15}
          fontWeight={500}
          mb={1}
          sx={{
            opacity: 0.8,
          }}
        >
          {title}
        </Typography>
      )}
      <Box
        sx={{
          height: 320,
          overflow: 'hidden',
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          border: '1px solid rgba(255, 255, 255, 0.03)',
          ...chartSx,
        }}
      >
        <img
          src='/bar-chart-example.svg'
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'bottom center',
            background: '#f5f5f5',
          }}
        />
      </Box>
    </Box>
  );
};

export default ActivityChart;
