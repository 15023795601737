import React, {
  CSSProperties,
  PropsWithChildren,
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Box, Skeleton, SxProps } from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import { IterableObject, ReactRenderElement } from '../../types/types';
import { useAppProvider } from '../../providers/app/app';
import { useWindowDimension } from '../../hooks/utils/window';
import { applyOpacityOnColor } from '../../utils/color';

type DataTableProps<T = {}> = {
  style?: CSSProperties;
  orderBy: 'ASC' | 'DESC';
  activeField?: keyof T;
  fields: (keyof T)[];
  data: T[];
  headerColumnStyle?: CSSProperties;
  columnStyle?: CSSProperties;
  fieldColumnStyleMap?: { [K in keyof Partial<T>]: CSSProperties };
  isLoading?: boolean;
  isLoadingMore?: boolean;
  loadingCount?: number;
  handleColumnItem?: (field: keyof T, item: T) => ReactRenderElement;
  handleHeaderColumnItem?: (
    field: keyof T,
    index: number,
  ) => ReactRenderElement;
  handleOnHeaderColumnClick?: (
    field: keyof T,
    order?: 'ASC' | 'DESC' | null,
    evt?: SyntheticEvent<HTMLDivElement>,
  ) => void;
  handleRowClick?: (item: T, evt: SyntheticEvent) => void;
};

type DataTableColumnProps = PropsWithChildren & {
  style?: CSSProperties;
  onClick?: (evt: SyntheticEvent<HTMLDivElement>) => void;
};

const DataTableColumn = ({
  children,
  style,
  onClick,
}: DataTableColumnProps) => {
  return (
    <Box className='data-table-column' style={style} onClick={onClick}>
      {children}
    </Box>
  );
};

type DataTableRowProps = PropsWithChildren & {
  isDarkMode?: boolean;
  style?: CSSProperties;
  sx?: SxProps;
  onClick?: (evt: SyntheticEvent) => void;
};
const DataTableRow = ({ sx, style, children, onClick }: DataTableRowProps) => {
  return (
    <Box className='data-table-row' style={style} sx={sx} onClick={onClick}>
      {children}
    </Box>
  );
};

const DataTable = <T,>({
  style,
  data,
  fields,
  columnStyle,
  orderBy,
  headerColumnStyle,
  fieldColumnStyleMap,
  activeField,
  isLoading,
  isLoadingMore,
  loadingCount = 3,
  handleColumnItem,
  handleHeaderColumnItem,
  handleOnHeaderColumnClick,
  handleRowClick,
}: DataTableProps<T>) => {
  const {
    isDarkMode,
    palettes: { main: palette },
  } = useAppProvider();
  useWindowDimension();
  const [, setMountCounter] = useState(0);

  const tableRef = useRef<HTMLDivElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const tableParentWidth = tableRef.current?.parentElement?.clientWidth;

  const defaultColumnStyle: CSSProperties = {
    fontSize: 15,
    width: 100,
    minHeight: 50,
    minWidth: 100,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    ...columnStyle,
  };
  const defaultHeaderColumnStyle: CSSProperties = {
    fontSize: 15,
    fontWeight: 500,
    userSelect: 'none',
    cursor: handleOnHeaderColumnClick ? 'pointer' : 'initial',
    display: 'flex',
    alignItems: 'center',
    ...headerColumnStyle,
  };

  const tableContainerWidth = tableContainerRef.current?.clientWidth;
  const rowStyle: CSSProperties = {
    display: 'flex',
    flexWrap: 'nowrap',
    width:
      tableContainerWidth! < tableParentWidth!
        ? tableParentWidth
        : tableContainerWidth,
  };
  const rowSx: SxProps = {
    ...(handleRowClick
      ? {
          cursor: 'pointer',
          userSelect: 'none',
          '&:hover': {
            background: `${applyOpacityOnColor(
              isDarkMode
                ? palette['md.ref.palette.neutral90']
                : palette['md.ref.palette.neutral10'],
              isDarkMode ? 0.1 : 0.08,
            )} !important`,
          },
        }
      : null),
  };

  const renderHeader = () => {
    return (
      <Box
        className='data-table-header'
        style={{
          ...rowStyle,
          marginBottom: 4,
        }}
      >
        {fields.map((key, index) => {
          return (
            <DataTableColumn
              key={index}
              style={{
                ...defaultColumnStyle,
                ...defaultHeaderColumnStyle,
                ...fieldColumnStyleMap?.[key],
              }}
              onClick={
                handleOnHeaderColumnClick
                  ? (evt: SyntheticEvent<HTMLDivElement>) =>
                      handleOnHeaderColumnClick(key, null, evt)
                  : undefined
              }
            >
              {
                (handleHeaderColumnItem
                  ? handleHeaderColumnItem(key, index)
                  : key) as ReactRenderElement
              }
              {activeField === key && (
                <ArrowDropDownOutlinedIcon
                  style={{
                    transform:
                      orderBy === 'ASC' ? 'rotateZ(180deg)' : 'rotateZ(0deg)',
                  }}
                />
              )}
            </DataTableColumn>
          );
        })}
      </Box>
    );
  };

  const renderBody = () => {
    return (
      <Box className='data-table-body'>
        {(isLoading && !data.length
          ? new Array(loadingCount).fill('loader' as any)
          : data
        ).map((item, index) => {
          let isEven = index % 2 === 0;
          let isLoader = item === 'loader';

          return (
            <DataTableRow
              key={`${isLoader ? 'loader_' : ''}${index}`}
              sx={rowSx}
              style={{
                ...rowStyle,
                background: !isEven
                  ? undefined
                  : `${applyOpacityOnColor(
                      isDarkMode
                        ? palette['md.ref.palette.neutral90']
                        : palette['md.ref.palette.neutral10'],
                      isDarkMode ? 0.05 : 0.03,
                    )}`,
              }}
              onClick={
                isLoader
                  ? undefined
                  : handleRowClick
                  ? (evt: SyntheticEvent) => handleRowClick(item, evt)
                  : undefined
              }
            >
              {fields.map((key, fIndex) => {
                return (
                  <DataTableColumn
                    key={`${isLoader ? 'loader_' : ''}${fIndex}`}
                    style={{
                      ...defaultColumnStyle,
                      ...fieldColumnStyleMap?.[key],
                    }}
                  >
                    {isLoader ? (
                      <Skeleton
                        variant='text'
                        style={{
                          width: '100%',
                          fontSize: 'inherit',
                        }}
                      />
                    ) : (
                      ((handleColumnItem
                        ? handleColumnItem(key, item)
                        : item[key]) as ReactRenderElement)
                    )}
                  </DataTableColumn>
                );
              })}
            </DataTableRow>
          );
        })}
      </Box>
    );
  };

  /**
   * When fields changed. Let's setup the counter for mounting.
   * This way, the calculation of the row's width get's recalculated.
   */
  useLayoutEffect(() => {
    setMountCounter((c) => ++c);
  }, [fields, setMountCounter]);

  return (
    <Box
      ref={tableRef}
      className='data-table'
      style={{
        display: 'flex',
        overflow: 'auto',
        width: tableParentWidth,
        ...style,
      }}
    >
      <Box
        ref={tableContainerRef}
        className='data-table-container'
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {renderHeader()}
        {renderBody()}
      </Box>
    </Box>
  );
};

export default DataTable;
