import React, {
  CSSProperties,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Stack, Typography } from '@mui/material';
import MonitorOutlinedIcon from '@mui/icons-material/MonitorOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';

import { generateUserKeystrokes, UserKeystroke } from '../types';
import {
  generateActivityApplicationItems,
  ActivityApplicationItem,
} from '../../Activities/types';
import { shuffle, sortBy } from '../../../utils/array';
import moment from 'moment';
import DataTable from '../../../components/DataTable/DataTable';
import { stripToFormatterWord, toTitleCase } from '../../../utils/string';
import { IterableObject } from '../../../types/types';

type Props = {
  dataTypeFilters: IterableObject<boolean>;
};

const activityAppFieldLabelMap: {
  [K in keyof Partial<UserTimeline>]: string;
} = {
  url: 'URL',
};

const agentFieldStyleMap: {
  [K in keyof Partial<UserTimeline>]: CSSProperties;
} = {
  id: {
    width: 100,
    minWidth: 100,
  },
  timestamp: {
    width: 260,
    minWidth: 260,
  },
  url: {
    width: 300,
    minWidth: 300,
  },
  keystrokes: {
    flex: 1,
    width: 0,
  },
};

const defaultAgentFields: (keyof UserTimeline)[] = [
  'timestamp',
  'url',
  'keystrokes',
];

const defaultActiveField: keyof UserTimeline = 'timestamp';

type ItemDataType = {
  data_type: 'keystrokes' | 'activity';
  activity: ActivityApplicationItem['domain'] | UserKeystroke['host'];
  duration?: string | null;
  url: ActivityApplicationItem['url'] | null;
  keystrokes: string | null;
};
type UserTimeline = UserKeystroke;

const ActivityKeystrokes = ({ dataTypeFilters }: Props) => {
  const [filters, setFilters] = useState<{
    active: keyof UserTimeline;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [dataTableKey, setDataTableKey] = useState(0);

  const data: UserTimeline[] = useMemo(() => {
    return generateUserKeystrokes(20);
  }, []);

  const filteredData = useMemo(() => {
    let value = sortBy<UserTimeline>(data, filters.active, true, filters.order);
    return value;
  }, [data, filters, dataTypeFilters]);

  const [selectedFields, setSelectedFields] = useState<(keyof UserTimeline)[]>(
    [],
  );

  const handleColumnItem = useCallback(
    (field: keyof UserTimeline, item: UserTimeline) => {
      let value: any = item[field];

      if (field === 'timestamp') {
        const iconStyle = {
          fontSize: 20,
          opacity: 0.5,
          marginTop: 1,
        };
        return (
          <Stack direction='row' gap={1} style={{ color: 'inherit' }}>
            {/* {item.data_type === 'activity' && (
              <MonitorOutlinedIcon style={iconStyle} />
            )}
            {item.data_type === 'keystrokes' && (
              <KeyboardAltOutlinedIcon style={iconStyle} />
            )} */}
            {`${moment.utc(value).local().format('MMM D, YYYY hh:mma Z')}`}
          </Stack>
        );
      }

      // if (field === 'activity') {
      //   if (item.data_type === 'activity') {
      //     value = (item as ActivityApplicationItem).domain;
      //   } else if (item.data_type === 'keystrokes') {
      //     value = (item as UserKeystroke).activity;
      //   }
      // }

      // if (field === 'duration') {
      //   if (item.data_type === 'activity') {
      //     value = (item as ActivityApplicationItem).duration;
      //   } else if (item.data_type === 'keystrokes') {
      //     value = null;
      //   }
      // }

      // if ((field === 'url' || field === 'domain') && item[field]) {
      //   let url = item[field];
      //   try {
      //     url = new URL(
      //       (item[field].startsWith('http') ? '' : 'https://') + item[field],
      //     ).href;
      //   } catch (e) {}
      //   return (
      //     <a href={url} target='_blank' className='m3-button-link'>
      //       {item[field]}
      //     </a>
      //   );
      // }

      return value;
    },
    [],
  );
  const handleHeaderColumnItem = useCallback(
    (field: keyof UserTimeline) =>
      activityAppFieldLabelMap[field] ??
      toTitleCase(stripToFormatterWord(field)),
    [],
  );
  const handleOnHeaderColumnClick = useCallback(
    (field: keyof UserTimeline, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );
  const handleOnFieldsChange = useCallback(
    (newFields: (keyof UserTimeline)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );

  useEffect(() => {
    setDataTableKey((key) => ++key);
  }, [dataTypeFilters, setDataTableKey]);

  return (
    <>
      <Typography component='div'>
        <DataTable
          key={dataTableKey}
          orderBy={filters.order}
          activeField={filters.active}
          fields={selectedFields.length ? selectedFields : defaultAgentFields}
          data={filteredData}
          fieldColumnStyleMap={agentFieldStyleMap}
          handleColumnItem={handleColumnItem}
          handleHeaderColumnItem={handleHeaderColumnItem}
          handleOnHeaderColumnClick={handleOnHeaderColumnClick}
        />
      </Typography>
    </>
  );
};

export default ActivityKeystrokes;
