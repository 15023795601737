import moment, { Moment } from 'moment';
import { isNotNullAndUndefined } from './object';

/**
 * NOTE: There's no America/Philadelphia where Go2 located, instead we use country having the same time zone.
 * Here are the list of time zones: https://gist.githubusercontent.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a/raw/8b5abde6f9c7d5570df3a2aa22325d7d20a8b5d7/moment-js-timezones.txt
 */
export const go2TZ = 'America/New_York';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%d sec',
    ss: '%d sec',
    m: '%d min',
    mm: '%d min',
    h: '%d hr',
    hh: '%d hr',
    d: '%d day',
    dd: '%d days',
    M: '%d mo',
    MM: '%d mo',
    y: '%d yr',
    yy: '%d yr',
  },
});

type DateWithoutTimezone = {
  date: string;
  time: string;
};
type DateWithoutTimezoneOptions = {
  dateFormat?: string;
  dateDelimiter?: string;
  timeFormat?: string;
  timeDelimiter?: string;
};
export function parseDateWithoutTimezone(
  inputDate: string,
  {
    dateFormat = 'YYYY-MM-DD',
    dateDelimiter = '-',
    timeFormat = 'hh:mma',
    timeDelimiter = ':',
  }: DateWithoutTimezoneOptions = {} as DateWithoutTimezoneOptions,
): DateWithoutTimezone {
  const [date = '', time = ''] = inputDate!.split('T');
  const [yyyy, mm, dd] = date.split(dateDelimiter);
  const [hour = '', minute = '', second = ''] = time.split(timeDelimiter);
  const setParams: { [key: string]: number } = {
    hour: +hour || 0,
    minute: +minute || 0,
    second: +second.replace('Z', '') || 0,
    millisecond: 0,
    year: +yyyy,
    // subtract by 1 as month is 0 index based
    // ie. 0 - January and is 11 - December
    month: +mm - 1,
    date: +dd,
  };
  Object.keys(setParams).forEach((key) => {
    if (isNaN(setParams[key])) {
      delete setParams[key];
    }
  });
  const m = moment().set(setParams);
  return {
    date: m.format(dateFormat),
    time: m.format(timeFormat),
  };
}

type HHMMSSOptions = {
  json?: boolean;
  hrDigit?: number;
  minDigit?: number;
  secDigit?: number;
  msDigit?: number;
  noHrs?: boolean;
};
export type HHMMSSOptionsJSONRet = {
  hasHours: boolean;
  hours: string | number;
  hasMinutes: boolean;
  minutes: string | number;
  hasSeconds: boolean;
  seconds: string | number;
  milliSeconds: string | number;
};
type HHMMSSOptionsRet = string | HHMMSSOptionsJSONRet;
export function toHHMMSS(
  num: string | number,
  option: HHMMSSOptions = {},
): HHMMSSOptionsRet {
  let sec_num = parseFloat(num as string) || 0;
  sec_num = sec_num <= 0 ? 0 : sec_num;
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);
  let milliSeconds = (sec_num - hours * 3600 - minutes * 60 - seconds) * 1000;
  let fHours: string | number = hours;
  let fMinutes: string | number = minutes;
  let fSeconds: string | number = seconds;
  let fMilliSeconds = +(milliSeconds + '').split('.')[0];
  const {
    json = false,
    hrDigit = 1,
    minDigit = 2,
    secDigit = 2,
    noHrs = true,
  } = option;
  if (hours < 10 && hrDigit > 1) {
    fHours = '0' + hours;
  }
  if (minutes < 10 && minDigit > 1) {
    fMinutes = '0' + minutes;
  }
  if (seconds < 10 && secDigit > 1) {
    fSeconds = '0' + seconds;
  }

  if (json) {
    return {
      hasHours: hours ? true : false,
      hours: fHours,
      hasMinutes: minutes ? true : false,
      minutes: fMinutes,
      hasSeconds: seconds ? true : false,
      seconds: fSeconds,
      milliSeconds: fMilliSeconds,
    } as HHMMSSOptionsJSONRet;
  } else {
    if (noHrs) return fMinutes + ':' + fSeconds;
    return fHours + ':' + fMinutes + ':' + fSeconds;
  }
}

export function hhmmssToSeconds(hhmmss = '', col = 3) {
  const fields = (hhmmss || '').trim().split(':');
  if (fields.length < col) {
    const remaining = col - fields.length;
    for (let i = 0; i < remaining; i++) fields.unshift('0');
  }
  const [hh = 0, mm = 0, ss = 0] = fields.map((t) => Math.abs(+t || 0));
  return hh * 3600 + mm * 60 + ss;
}

/**
 * Returns a date range that starts on Monday
 */
export function getWeekRangeStartsInMonday(endDate: string | Moment): {
  start: Moment;
  end: Moment;
} {
  // Parse the end date
  let end = moment(endDate);

  // Get the start of the week (Monday)
  let start;
  if (end.day() === 0) {
    // If the end date is Sunday
    start = end.clone().subtract(6, 'days');
  } else {
    // Moment.js sets Sunday as the start of the week by default, so we add one day
    start = end.clone().startOf('week').add(1, 'days');
  }

  return {
    start: start.clone().startOf('day'),
    end: end.clone().endOf('day'),
  };
}

export function getWeekRangeStartsInSunday(endDate: string | Moment): {
  start: Moment;
  end: Moment;
} {
  // Parse the end date
  let end = moment(endDate).endOf('week');
  let start = moment(endDate).startOf('week');

  return {
    start: start.clone().startOf('day'),
    end: end.clone().endOf('day'),
  };
}

export function formatDate(dateFormat: string, date?: Moment | null) {
  return moment(isNotNullAndUndefined(date) ? date : new Date()).format(
    dateFormat,
  );
}

// Helper function to convert time string to seconds
export const getSeconds = (time: string) => {
  const [hours, minutes] = time.split(':');
  const [minutesWithoutAMPM, am_pm] = minutes.split(' ');

  let hoursIn24Hour = parseInt(hours);
  if (am_pm === 'PM' && hoursIn24Hour < 12) hoursIn24Hour += 12;
  if (am_pm === 'AM' && hoursIn24Hour === 12) hoursIn24Hour -= 12;

  return hoursIn24Hour * 3600 + parseInt(minutesWithoutAMPM) * 60;
};

export function getTimeDifferenceBetweenStartAndEnd(
  start: string,
  end: string,
) {
  let startTime = moment(start, 'hh:mm a');
  let endTime = moment(end, 'hh:mm a');
  let overlap = false;

  if (endTime.isBefore(startTime)) {
    overlap = true;
    endTime.add(1, 'days');
  }

  let duration = moment.duration(endTime.diff(startTime));
  let hours = duration.asHours();

  return {
    hours,
    overlap,
  };
}

export function getDurationSeconds(
  endDate: string | Moment,
  startDate: string | Moment,
) {
  return moment(endDate).diff(startDate, 'millisecond') / 1000;
}
