import React, {
  CSSProperties,
  FC,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import {
  AppBar,
  Box,
  CircularProgress,
  Divider,
  Modal,
  Stack,
  SxProps,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import AppBarControlPanel, {
  AppBarControlPanelProps,
} from './AppBarControlPanel';
import AppToolbar from './AppToolbar';
import { navRailWidth } from './NavRailMenuList';
import { getSurfaceOpacityByElevation } from './M3/M3SurfaceContainer';

import { useAppProvider } from '../providers/app/app';
import { MenuItem, ReactRenderElement } from '../types/types';
import { applyOpacityOnColor } from '../utils/color';
import { useAuthForm } from '../providers/auth/auth';
import { M3IconButton } from './M3/M3Button';
import ProfileControl from './Profile/ProfileControl';

type Props = {
  children?: any;
  noAppDrawer?: boolean;
};

const menuIconButtonSx: SxProps = {};

function AppNavigationBar({ children, noAppDrawer }: Props) {
  let { logout } = useAuthForm();
  const navigate = useNavigate();
  const { pathname, search: locationSearch } = useLocation();
  const {
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
    setDarkMode,
    withNavRail,
    updateActionKeyCounter,
    setIsDrawerOpen,
  } = useAppProvider();

  const standaloneMenus: (MenuItem & Partial<AppBarControlPanelProps>)[] = [
    {
      id: 'dark-mode-switch',
      navigate: false,
      name: isDarkMode ? 'Light Mode' : 'Dark Mode',
      icon: isDarkMode ? <LightModeOutlinedIcon /> : <DarkModeOutlinedIcon />,
      onClick: () => {
        setDarkMode(!isDarkMode);
        updateActionKeyCounter('toggle_switch_dark_mode');
      },
    },
    /*
    {
      id: 'help',
      name: 'Help',
      icon: <HelpOutlineOutlinedIcon />,
      withPopover: true,
      menus: [
        {
          id: 'help-center',
          name: 'Help Center',
          basePath: '/help/center',
          path: `/help/center`,
          active: pathname.includes('/help/center'),
          onClick: () => {
            posthog.capture('help center clicked');
          },
        },
        {
          id: 'help-view-updates',
          name: 'View Updates',
          onClick: () => {
            posthog.capture('view go2 portal updates clicked');
            window.open(
              'https://docs.google.com/document/d/1y8JxcqgFaOlT8J56mBYwjMljUFdA-M43kPKBzVyhxlw',
              '_target',
            );
          },
        },
      ],
    },
    {
      id: 'settings',
      name: 'Settings',
      icon: <SettingsOutlinedIcon />,
      basePath: '/settings',
      path: '/settings/personal/profile',
      active: pathname.includes('/settings'),
      withPopover: false,
    },
    */
    // {
    //   id: 'logout',
    //   navigate: false,
    //   name: 'Logout',
    //   icon: <LogoutIcon />,
    //   onClick: () => {},
    // },
  ];

  return (
    <>
      <AppBar
        elevation={0}
        position='fixed'
        sx={{
          background: isDarkMode
            ? 'var(--md-sys-color-background-dark)'
            : 'var(--md-sys-color-background-light)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <AppToolbar
          style={{
            paddingLeft: 0,
            background: applyOpacityOnColor(
              isDarkMode
                ? palette['md.ref.palette.primary80']
                : palette['md.ref.palette.primary40'],
              getSurfaceOpacityByElevation(2),
            ),
          }}
        >
          <Box flex={1} display='flex' alignItems='center'>
            <Box
              sx={{
                height: toolbarHeight,
                width: navRailWidth,
                minWidth: navRailWidth,
                background: noAppDrawer
                  ? undefined
                  : isDarkMode
                  ? 'var(--md-sys-color-background-dark)'
                  : 'var(--md-sys-color-background-light)',
                mr: 3,
              }}
            >
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: noAppDrawer
                    ? undefined
                    : applyOpacityOnColor(
                        isDarkMode
                          ? palette['md.ref.palette.primary80']
                          : palette['md.ref.palette.primary40'],
                        getSurfaceOpacityByElevation(5),
                      ),
                }}
              >
                {/* <img
                src='/icon-128.png'
                width={36}
                height={36}
                style={{
                  borderRadius: 4,
                  display: 'block',
                }}
              /> */}
                <M3IconButton onClick={() => setIsDrawerOpen(true)}>
                  <MenuOutlinedIcon />
                </M3IconButton>
              </Box>
            </Box>
          </Box>
          <Stack
            gap={1}
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
          >
            {standaloneMenus.map(
              (menu: MenuItem & Partial<AppBarControlPanelProps>) => {
                return (
                  <AppBarControlPanel
                    key={menu.id}
                    tooltip={{
                      title: menu.name as string,
                      placement: 'bottom',
                    }}
                    active={pathname.includes(menu.basePath!)}
                    redirectTo={menu.path}
                    icon={menu.icon}
                    onClick={menu.onClick}
                    iconButtonSx={menuIconButtonSx}
                    withPopover={menu.withPopover}
                    menus={menu.menus}
                  />
                );
              },
            )}
            <Divider orientation='vertical' flexItem sx={{ ml: 1, mr: 2 }} />
            <ProfileControl />
          </Stack>
          {/* <M3Button
            sx={{
              pl: 2,
              pr: 2,
            }}
            onClick={logout}
            data-testid='logout-button'
          >
            <LogoutIcon sx={{ fontSize: 22 }} />
            Log out
          </M3Button> */}
        </AppToolbar>
      </AppBar>
      <AppToolbar />
    </>
  );
}

export default AppNavigationBar;
